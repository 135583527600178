@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "Rock Salt", cursive;
  src: url(https://fonts.gstatic.com/s/rocksalt/v11/MwQ0bhv11fWD6QsAVOZrt0M6.woff2) format("woff2");
}

html {
  overflow-y: scroll;
}

body {
  display: -ms-grid;
  display: grid;
  place-items: center;
  width: 100%;
  text-align: center;
}

.App {
  margin-top: 5vh;
  display: -ms-grid;
  display: grid;
  place-items: center;
  width: 600px;
  background: white;
}

.logo {
  width: 300px;
  cursor: pointer;
}

.App {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
  padding: 5%;
  border-radius: 8px;
  box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.41);
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.App input::-webkit-outer-spin-button,
.App input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App input[type="number"] {
  -moz-appearance: textfield;
}

.App .tnc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  color: #000000;
  padding: 20px 0;
}

.App .tnc-button label {
  cursor: pointer;
}

.App .modal-bg {
  position: absolute;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: "1rem";
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  opacity: 1;
  overflow: hidden;
  overflow-y: auto;
}

.App .modal-bg .modal-container {
  position: absolute;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 3%;
  right: 3%;
  left: 3%;
  bottom: 3%;
  padding: "1rem";
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.App .modal-bg .modal-container label {
  cursor: pointer;
  position: fixed;
  top: 5%;
}

.App .modal-bg .modal-container .modal-x {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 20px;
  font-size: 30px;
  color: #000000;
}

.App .modal-bg .modal-container .modal-ol {
  padding: 50px;
}

.App .modal-bg .modal-container .modal-ol p {
  text-align: justify;
  word-break: inherit;
  padding: 10px;
}

.App .modal-bg .modal-container .modal-ol .modal-ol2 {
  text-align: justify;
  word-break: inherit;
}

.App .modal-bg .modal-container .modal-ol .modal-ol2 li {
  padding-left: 30px;
}

.App .pdf {
  position: relative;
}

.App .pdf span {
  position: absolute;
  z-index: 1;
  left: 50px;
}

.App .space {
  height: 100px;
}

.App .ver-number {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  top: 0;
  font-size: 10px;
}

.App .topContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.App .topContainer button {
  background: white;
  outline-style: none;
  border-style: none;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.21);
  padding: 15px 15px;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
}

.App .tooltiptext2 {
  text-align: left !important;
  font-size: 0.7rem;
}

.App form {
  margin: 20px 0 20px 0;
  display: -ms-grid;
  display: grid;
  place-items: center;
  text-align: center;
  position: relative;
  /* Tooltip text */
  /* Show the tooltip text when you mouse over the tooltip container */
}

.App form .info-box {
  text-align: left;
  font-size: 0.8rem;
  padding-left: 20%;
}

.App form .inputContainer {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 500px;
}

.App form .inputContainer .inputTitle {
  margin: 0 1vw 0 0;
  width: 150px;
  text-align: left;
}

.App form .inputContainer .inputSeparator {
  width: 50px;
  margin: 0 1vw;
}

.App form .inputContainer input,
.App form .inputContainer textarea,
.App form .inputContainer select {
  padding: 15px;
  width: 200px;
  outline-style: none;
  border-style: none;
  border-style: solid;
  border-width: 1px;
  border-color: #acacac;
  border-radius: 5px;
  font-size: 15px;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
}

.App form .inputContainer .RMContainer {
  /* Firefox */
}

.App form .inputContainer .RMContainer label {
  position: absolute;
  margin: 15px;
}

.App form .inputContainer .RMContainer input {
  padding-left: 50px;
  width: 165px;
}

.App form .inputContainer .RMContainer input::-webkit-outer-spin-button,
.App form .inputContainer .RMContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App form .inputContainer .RMContainer input[type="number"] {
  -moz-appearance: textfield;
}

.App form .inputContainer .BuiltContainer {
  /* Firefox */
}

.App form .inputContainer .BuiltContainer label {
  position: absolute;
  right: 0;
  margin: 16px;
  margin-right: 40px;
}

.App form .inputContainer .BuiltContainer input::-webkit-outer-spin-button,
.App form .inputContainer .BuiltContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App form .inputContainer .BuiltContainer input[type="number"] {
  -moz-appearance: textfield;
}

.App form .inputContainer .BuiltContainer2 {
  /* Firefox */
}

.App form .inputContainer .BuiltContainer2 label {
  position: absolute;
  right: 0;
  margin: 16px;
  margin-right: 80px;
}

.App form .inputContainer .BuiltContainer2 input::-webkit-outer-spin-button,
.App form .inputContainer .BuiltContainer2 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App form .inputContainer .BuiltContainer2 input[type="number"] {
  -moz-appearance: textfield;
}

.App form .inputContainer textarea {
  resize: none;
}

.App form .inputContainer .inputPrice input {
  padding-left: 50px;
}

.App form .inputContainer span {
  position: absolute;
  padding-left: 20px;
}

.App form .privacy {
  display: flex;
  flex-direction: column;
}

.App form .privacy textarea {
  height: 300px;
  resize: none;
  padding: 20px;
}

.App form .privacy label {
  text-align: justify;
  text-justify: newspaper;
}

.App form .pdpa p {
  text-align: justify;
  text-justify: newspaper;
}

.App form .sign {
  margin-top: 2vh;
  width: 350px;
  height: 200px;
  border-style: solid;
  border-width: 1px;
}

.App form .type {
  border: none;
  width: 90%;
  height: 90%;
  font-size: 3rem;
  text-align: center;
  font-family: "Rock Salt", cursive;
  outline-style: none;
  -ms-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 1;
}

.App form .signed {
  margin-top: 2vh;
  width: 50%;
  max-height: 100px;
}

.App form .canvasType {
  display: none;
  position: absolute;
  background: none;
  z-index: 0;
}

.App form .signaturePad button {
  margin-top: 2vh;
}

.App form .checkbox label {
  display: flex;
  flex-direction: row;
  text-align: justify;
}

.App form hr {
  width: 350px;
  margin: 20px 0;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
}

.App form .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

.App form .tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  top: 100%;
  left: 30%;
  margin-left: -60px;
  /* Use half of the width (120/2 = 60), to center the tooltip */
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.App form .tooltip:hover .tooltiptext {
  visibility: visible;
  width: 500px;
  padding: 10px;
  text-align: justify;
}

.App .alertContainer {
  width: 600px;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.App .alertContainer .alert {
  color: #000000;
  font-weight: 400;
  width: 250px;
}

@media only screen and (max-width: 700px) {
  body {
    width: 100vw;
  }
  body .logo {
    width: 50vw;
  }
  .App {
    width: 98vw;
    margin: 50px 1vw 0 1vw;
    padding: 0;
    padding-bottom: 50px;
    margin-bottom: 50px;
  }
  .App form {
    width: 100%;
  }
  .App form .inputContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
  }
  .App form .inputContainer .inputSeparator {
    display: none;
  }
  .App form .inputContainer .inputTitle {
    text-align: left;
    width: 80%;
  }
  .App form .inputContainer input,
  .App form .inputContainer select {
    width: 80%;
  }
  .App form .privacy {
    width: 80%;
  }
  .App form .BuiltContainer {
    width: 100%;
  }
  .App form .BuiltContainer label {
    margin-right: 18vw !important;
  }
  .App form .BuiltContainer2 {
    width: 100%;
  }
  .App form .BuiltContainer2 label {
    margin-right: 18vw !important;
  }
  .App form .RMContainer {
    width: 100%;
  }
  .App form .RMContainer .inputPrice {
    width: 72% !important;
  }
  .App form .pdpa {
    width: 80%;
  }
  .App form .sign {
    width: 80%;
    height: 200px;
  }
  .App form .signed {
    width: 80%;
    height: 200px;
  }
  .App .alertContainer {
    width: 80%;
  }
}

.submit {
  width: 250px;
  background: white;
  outline-style: none;
  border-style: none;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.21);
  padding: 15px 15px;
  border-radius: 5px;
  color: #000000;
  margin: 10% 0 0% 0;
  cursor: pointer;
}
/*# sourceMappingURL=App.css.map */
